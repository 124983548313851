import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/button/button";
import { RatingSlider } from "../../../../components/rating-slider/rating-slider";
import { SmallTitle } from "../../../../components/small-title/small-title";
import { StepsDots } from "../../../../components/steps-dots/steps-dots";
import {
  setHideMenuItems,
  setShowLoader,
  setTitle,
} from "../../../../store/app-settings/app-settings-slice";
import {
  assignContentsToUser,
  changeProfile,
} from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import { CategoriesNames } from "../../../../utils/enums";
import "./categories-roadmap.scss";
import { validateRating } from "../../../../utils/helper";
import { ReactComponent as HealthCategoryIcon } from "../../../../assets/icons/health-category.svg";
import { ReactComponent as FamilyCategoryIcon } from "../../../../assets/icons/family-category.svg";
import { ReactComponent as LegacyCategoryIcon } from "../../../../assets/icons/legacy-category.svg";
import { ReactComponent as LeisureCategoryIcon } from "../../../../assets/icons/leisure-category.svg";
import { ReactComponent as ProfessionalOrCareerCategoryIcon } from "../../../../assets/icons/professional-or-career-category.svg";
import { ReactComponent as SocialAndCommunityCategoryIcon } from "../../../../assets/icons/social-and-community.svg";
import { ReactComponent as SelfAndWellbeingCategoryCategoryIcon } from "../../../../assets/icons/self-and-wellbeing-category.svg";
import { ReactComponent as SpiritualCategoryIcon } from "../../../../assets/icons/spiritual-category.svg";
import { ReactComponent as EducationCategoryIcon } from "../../../../assets/icons/education-category.svg";
import { ReactComponent as FinancialCategoryIcon } from "../../../../assets/icons/financial-category.svg";
import { ReactComponent as OtherCategoryIcon } from "../../../../assets/icons/other-category.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIncon } from "../../../../assets/icons/minus.svg";
import { useTranslation } from "react-i18next";
import { setHopeJourneyGuidePopupOpen } from "../../../../store/app-settings/app-settings-slice";
import HopeJourneyGuidePopup from "../../app-popups/hope-journey-guide-popup/HopeJourneyGuidePopup";

export const CategoriesRoadmap = () => {
  const { t, i18n } = useTranslation("OnBoarding");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const hopeJourneyGuidePopupOpen = useSelector(
    (state) => state.appSettingsReducer.hopeJourneyGuidePopupOpen
  );
  const [info, setInfo] = useState({
    HealthEvaluation: 0,
    SelfAndWellbeingEvaluation: 0,
    FamilyEvaluation: 0,
    LeisureEvaluation: 0,
    SpiritualEvaluation: 0,
    SocialAndCommunityEvaluation: 0,
    ProfessionalEvaluation: 0,
    FinancialEvaluation: 0,
    EducationEvaluation: 0,
    LegacyEvaluation: 0,
    OtherName: "",
    OtherEvaluation: 0,
  });
  const [showError, setShowError] = useState([]);
  const infoRefs = useRef([]);
  const [showOther, setShowOther] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profile) {
      setShowError(Array(Object.keys(info).length).fill(null));

      for (var key of Object.keys(info)) {
        if (profile[key]) {
          changeInfo(key, profile[key]);
        } else if (profile.CustomFields[key]) {
          const i18Key = `OnBoarding:CategoriesRoadmap.Options.${profile.CustomFields[key]}`;
          const isKeyExist = i18n.exists(i18Key);
          changeInfo(key, isKeyExist ? t(i18Key) : profile.CustomFields[key]);
        }
      }
      if (profile.CustomFields?.OtherName) setShowOther(true);
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      dispatch(setTitle(t("CategoriesRoadmap.title")));
    }
  }, [profile, i18n]);

  useEffect(() => {
    dispatch(setHopeJourneyGuidePopupOpen(true));
  }, []);

  const changeInfo = (name, value) => {
    setInfo((prevState) => ({
      ...prevState,
      [name]: name === "OtherName" ? value : parseInt(value),
    }));
  };

  const handleChangeSlider = (value, name) => {
    changeInfo(name, value);
  };

  const handleChangeInput = (e) => {
    changeInfo(e.target.name, e.target.value);
  };

  const saveCategories = async () => {
    if (!validateRating(showError, setShowError, info, 1, 8)) return;
    try {
      dispatch(setShowLoader(true));

      let data = {
        CustomFields: {
          HealthEvaluation: info.HealthEvaluation,
          SelfAndWellbeingEvaluation: info.SelfAndWellbeingEvaluation,
          FamilyEvaluation: info.FamilyEvaluation,
          LeisureEvaluation: info.LeisureEvaluation,
          SpiritualEvaluation: info.SpiritualEvaluation,
          SocialAndCommunityEvaluation: info.SocialAndCommunityEvaluation,
          ProfessionalEvaluation: info.ProfessionalEvaluation,
          FinancialEvaluation: info.FinancialEvaluation,
          EducationEvaluation: info.EducationEvaluation,
          LegacyEvaluation: info.LegacyEvaluation,
          OtherName: info.OtherName,
          OtherEvaluation: info.OtherEvaluation,
        },
        OnOnboardingFinish: true,
      };
      dispatch(setHideMenuItems(false));
      if (!profile.OnboardingFinished) {
        data.OnboardingFinished = true;
        const sortedEvaluationsArr = [
          ...new Set(
            Object.keys(info)
              .filter((key) => key !== "OtherName")
              .map((key) => {
                return info[key];
              })
              .sort((a, b) => (a < b ? 1 : -1))
          ),
        ];
        const maxRatings = sortedEvaluationsArr.slice(0, 2);
        let categoriesWithMaxRatings = [];
        for (let key in info) {
          if (key !== "OtherName")
            if (maxRatings.includes(parseInt(info[key]))) {
              const keyToPush =
                key === "OtherEvaluation"
                  ? info.OtherName
                  : CategoriesNames[key];
              categoriesWithMaxRatings.push(keyToPush);
            }
        }

        const res = await Api.put("user/profile", data);
        dispatch(setShowLoader(false));
        if (res.data) {
          dispatch(changeProfile(res.data.payload));
          navigate("/maps/create/category", {
            replace: true,
            state: { categoriesWithMaxRatings },
          });
        }
      } else {
        const res = await Api.put("user/profile", data);
        dispatch(setShowLoader(false));
        if (res.data) {
          dispatch(changeProfile(res.data.payload));
          navigate(-1);
        }
      }
    } catch (error) {
      dispatch(setShowLoader(false));
      window.location.reload();
    }
  };

  const navigateBack = () => {
    navigate("/onboarding/my-health");
  };

  return (
    <div className="categories-container">
      {hopeJourneyGuidePopupOpen &&
        ["+972587897623", "+972547898398", "+972525813751"].includes(
          profile?.Phone
        ) && (
          <HopeJourneyGuidePopup
            close={() => {
              dispatch(setHopeJourneyGuidePopupOpen(false));
            }}
          />
        )}
      <p className="categories-title">{t("CategoriesRoadmap.info")}</p>

      <div className="categories-sliders">
        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.health")}</SmallTitle>
          <HealthCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.HealthEvaluation}
          changeHandler={handleChangeSlider}
          name="HealthEvaluation"
          isError={showError[0]}
          ref={(el) =>
            (infoRefs.current[Object.keys(info).indexOf("HealthEvaluation")] =
              el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.wellbeing")}</SmallTitle>
          <SelfAndWellbeingCategoryCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.SelfAndWellbeingEvaluation}
          changeHandler={handleChangeSlider}
          name="SelfAndWellbeingEvaluation"
          isError={showError[1]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("SelfAndWellbeingEvaluation")
            ] = el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.family")}</SmallTitle>
          <FamilyCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.FamilyEvaluation}
          changeHandler={handleChangeSlider}
          name="FamilyEvaluation"
          isError={showError[2]}
          ref={(el) =>
            (infoRefs.current[Object.keys(info).indexOf("FamilyEvaluation")] =
              el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.leisure")}</SmallTitle>
          <LeisureCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.LeisureEvaluation}
          changeHandler={handleChangeSlider}
          name="LeisureEvaluation"
          isError={showError[3]}
          ref={(el) =>
            (infoRefs.current[Object.keys(info).indexOf("LeisureEvaluation")] =
              el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.spiritual")}</SmallTitle>
          <SpiritualCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.SpiritualEvaluation}
          changeHandler={handleChangeSlider}
          name="SpiritualEvaluation"
          isError={showError[4]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("SpiritualEvaluation")
            ] = el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.social")}</SmallTitle>
          <SocialAndCommunityCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.SocialAndCommunityEvaluation}
          changeHandler={handleChangeSlider}
          name="SocialAndCommunityEvaluation"
          isError={showError[5]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("SocialAndCommunityEvaluation")
            ] = el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.professional")}</SmallTitle>
          <ProfessionalOrCareerCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.ProfessionalEvaluation}
          changeHandler={handleChangeSlider}
          name="ProfessionalEvaluation"
          isError={showError[6]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("ProfessionalEvaluation")
            ] = el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.financial")}</SmallTitle>
          <FinancialCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.FinancialEvaluation}
          changeHandler={handleChangeSlider}
          name="FinancialEvaluation"
          isError={showError[7]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("FinancialEvaluation")
            ] = el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.education")}</SmallTitle>
          <EducationCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.EducationEvaluation}
          changeHandler={handleChangeSlider}
          name="EducationEvaluation"
          isError={showError[8]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("EducationEvaluation")
            ] = el)
          }
        />

        <div className="flex category-title">
          <SmallTitle>{t("CategoriesRoadmap.legacy")}</SmallTitle>
          <LegacyCategoryIcon className="svg-icon" />
        </div>
        <RatingSlider
          min={0}
          max={7}
          value={info.LegacyEvaluation}
          changeHandler={handleChangeSlider}
          name="LegacyEvaluation"
          isError={showError[9]}
          ref={(el) =>
            (infoRefs.current[Object.keys(info).indexOf("LegacyEvaluation")] =
              el)
          }
        />

        <button
          className="other-button flex"
          onClick={() => {
            setInfo((prev) => ({
              ...prev,
              OtherName: "",
              OtherEvaluation: 0,
            }));
            setShowOther(!showOther);
          }}
        >
          {!showOther ? <PlusIcon /> : <MinusIncon />}
          <p>{t("CategoriesRoadmap.other")}</p>
        </button>
        {showOther && (
          <>
            <SmallTitle>{t("CategoriesRoadmap.other-name")}</SmallTitle>
            <Input
              type="text"
              name="OtherName"
              onChange={handleChangeInput}
              value={info.OtherName}
              maxLength={15}
            />

            <div className="flex category-title">
              <SmallTitle>{t("CategoriesRoadmap.other-rating")}</SmallTitle>
              <OtherCategoryIcon className="svg-icon" />
            </div>
            <RatingSlider
              min={0}
              max={7}
              value={info.OtherEvaluation}
              changeHandler={handleChangeSlider}
              name="OtherEvaluation"
              isError={showError[11] && info.OtherName}
              ref={(el) =>
                (infoRefs.current[
                  Object.keys(info).indexOf("OtherEvaluation")
                ] = el)
              }
            />
          </>
        )}
      </div>
      {profile && profile.OnboardingFinished ? (
        <>
          <div className="flex category-title">
            <Button onClick={() => navigate(-1)}>{t("Common:back")}</Button>
            <Button onClick={saveCategories}>{t("Common:save")}</Button>
          </div>
        </>
      ) : (
        <StepsDots
          totalSteps={7}
          stepNumber={7}
          next={saveCategories}
          back={navigateBack}
        />
      )}
    </div>
  );
};
